<template>
  <v-container  fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-row>
        <v-col class="mt-0 pt-0 pb-0 xs" cols="12" sm="12" md="12" lg="12">
          <v-card>
            <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>

            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-2" cols="12">
          <v-card>
            <v-card-text>
              <v-text-field
                v-model="vm.name"
                label="Name"
                v-validate="'required'"
                data-vv-as="Name"
                name="name"
                :error-messages="errors.collect('name')"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    vm: {status:"Draft"},
   
  }),
  mounted() {
    this.init();
  },
  
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }

  },
  methods: {
    init() {
      
    },
    close() {
      this.$router.push("/invoicing-billing");      
    },
    validateBeforeSubmit() {
      //let me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          
        //   if (me.vm.status == undefined) {
        //     me.vm.status = "Draft";
        //     me.vm.isDeleted = false;
        //   }
        //   goalService
        //     .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
        //     .then(ref => {
        //       if (ref != undefined) {
        //         me.vm.id = ref.id;
        //       }
        //       me.$store.commit("setCurrentGoal", me.vm);

        //       me.$store.dispatch("setToast", {
        //         message: "Goal saved",
        //         color: "success"
        //       });
              
        //     })
        //     .catch(error => {
        //       console.error("Error saving goal: ", error);
        //     });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
  }
};
</script>
